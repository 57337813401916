import request from 'utils/request';
const api = {
  FINANCE_CONTRACT_LIST: '/api/finance/contract/list',
  FINANCE_CONTRACT_ADD: '/api/finance/contract/save',
  FINANCE_CONTRACT_REMOVE: '/api/finance/contract/remove',
  FINANCE_CONTRACT_UPDATE: '/api/finance/contract/update',
  FINANCE_CONTRACT_SELECTBYID: '/api/finance/contract/selectById',
  CONTRACT_FILE: '/api/finance/import/file',
  CONTRACT_SELECTCONTRACTTYPE: '/api/finance/contract/selectContractType',
  SEARCHLIST: '/api/finance/contract/searchList',
  CONTRACT_SELECTADVERTISERS: '/api/finance/contract/selectAdvertisersAll',
  CONTRACT_SELECTADVLEGALNAMEALL: '/api/finance/contract/selectAdvLegalNameAll',
};

/**
 * 获取 运营对账流水（分页）
 * @param params
 * @returns {*}
 */
export function fetchPage(params) {
  return request({
    url: api.FINANCE_CONTRACT_LIST,
    method: 'get',
    params,
  });
}

export function getContractList(params) {
  return request({
    url: api.SEARCHLIST,
    method: 'get',
    params,
  });
}

export function save(data) {
  return request({
    url: api.FINANCE_CONTRACT_ADD,
    method: 'post',
    data,
  });
}

export function remove(id) {
  return request({
    url: api.FINANCE_CONTRACT_REMOVE + '?id=' + id,
    method: 'delete',
    id,
  });
}

export function edit(data) {
  return request({
    url: api.FINANCE_CONTRACT_UPDATE,
    method: 'post',
    data,
  });
}

export function selectDetail(params) {
  return request({
    url: api.FINANCE_CONTRACT_SELECTBYID,
    method: 'get',
    params,
  });
}

export function uploadFile(data) {
  return request({
    url: api.CONTRACT_FILE,
    method: 'post',
    data,
  });
}

export function selectContractType() {
  return request({
    url: api.CONTRACT_SELECTCONTRACTTYPE,
    method: 'get',
  });
}

export function selectAdvertisersAll() {
  return request({
    url: api.CONTRACT_SELECTADVERTISERS,
    method: 'get',
  });
}

export function selectAdvLegalNameAll() {
  return request({
    url: api.CONTRACT_SELECTADVLEGALNAMEALL,
    method: 'get',
  });
}
